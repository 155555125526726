import "./App.css";
import Page from "./Page";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import NotFound from "./Components/NotFound/NotFound";
import FailedPopup from "./Components/PaymtPopup/FailedPopup";
import PhonepePopup from "./Components/PaymtPopup/PhonepePopup";
import NotFoundLaptop from "./Components/NotFound/NotFoundLaptop";
import { useEffect, useState } from "react";

function App() {
  // const [isMobile, setIsMobile] = useState(window.innerWidth <= 720);
  // const [hasRedirected, setHasRedirected] = useState({
  //   mobile: false,
  //   laptop: false,
  // });
  // const navigate = useNavigate();


  // useEffect(() => {
  //   const handleResize = () => {
  //     const isCurrentlyMobile = window.innerWidth <= 720;

  //     if (isCurrentlyMobile && !hasRedirected.mobile) {
  //       const currentPath = window.location.pathname
  //         .split("/")
  //         .slice(-2)
  //         .join("/");
  //       navigate(`/${currentPath}`);
  //       setHasRedirected({
  //         mobile: true,
  //         laptop: false,
  //       });
  //     }

  //     if (!isCurrentlyMobile && !hasRedirected.laptop) {
  //       const currentPath = window.location.pathname
  //         .split("/")
  //         .slice(-2)
  //         .join("/");
  //       navigate(`/laptop/${currentPath}`);
  //       setHasRedirected({
  //         mobile: false,
  //         laptop: true,
  //       });
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => window.removeEventListener("resize", handleResize);
  // }, [hasRedirected, navigate]);

  return (
    <div>
      {/* <BrowserRouter> */}
      <Routes>
        <Route
          path="*"
          // element={isMobile ? <NotFoundLaptop /> : <NotFoundLaptop />}
          element={<NotFoundLaptop />}
        />

        {/* Payment routes */}
        <Route
          path="/success-payment/:campaignName/:id"
          element={<PhonepePopup />}
        />
        <Route
          path="/failed-payment/:campaignName/:id"
          element={<FailedPopup />}
        />
        <Route path="/:campaignName/:id" element={<Page />} />

        {/* {isMobile ? (
          <Route path="/:campaignName/:id" element={<Page />} />
        ) : (
          <Route
            path="/laptop/:campaignName/:id"
            element={<NotFoundLaptop />}
          />
        )} */}
        {/* laptop route path */}
        {/* <Route path="/laptop/:campaignName/:id" element={<NotFoundLaptop />} /> */}
      </Routes>
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;

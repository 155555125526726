// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media(max-width:1024px){
    .easy-img-step{
        display: none;
    }
    .step-box-contain{
        width: 100%;
    }
}
@media(max-width:720px){
    .easy-step-box{
        padding-top: 30px !important;
    }
    .easy-step-started{
        font-weight: 700;
        font-size: 18px;
        line-height: 22.68px;
        color: #FEFFFA;
    }
    .three-easy-step{
        color: #656565;
        font-weight: 600;
        font-size: 12px;
        line-height: 15.12px;
    }
    .easy-step-h{
        font-weight: 600;
        font-size: 12px;
        color: #FEFFFA;
        line-height: 17.64px;
    }
    .easy-step-p{
        font-weight: 600;
        font-size: 10px;
        line-height: 15.12px;
        color: #656565;
    }
}


@media(max-width:374px){
    .easy-step-h{
        font-size: 10px !important;
    }
    .easy-step-p{
        font-size: 9px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/EasyStep/EasyStep.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;IACjB;IACA;QACI,WAAW;IACf;AACJ;AACA;IACI;QACI,4BAA4B;IAChC;IACA;QACI,gBAAgB;QAChB,eAAe;QACf,oBAAoB;QACpB,cAAc;IAClB;IACA;QACI,cAAc;QACd,gBAAgB;QAChB,eAAe;QACf,oBAAoB;IACxB;IACA;QACI,gBAAgB;QAChB,eAAe;QACf,cAAc;QACd,oBAAoB;IACxB;IACA;QACI,gBAAgB;QAChB,eAAe;QACf,oBAAoB;QACpB,cAAc;IAClB;AACJ;;;AAGA;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":["@media(max-width:1024px){\n    .easy-img-step{\n        display: none;\n    }\n    .step-box-contain{\n        width: 100%;\n    }\n}\n@media(max-width:720px){\n    .easy-step-box{\n        padding-top: 30px !important;\n    }\n    .easy-step-started{\n        font-weight: 700;\n        font-size: 18px;\n        line-height: 22.68px;\n        color: #FEFFFA;\n    }\n    .three-easy-step{\n        color: #656565;\n        font-weight: 600;\n        font-size: 12px;\n        line-height: 15.12px;\n    }\n    .easy-step-h{\n        font-weight: 600;\n        font-size: 12px;\n        color: #FEFFFA;\n        line-height: 17.64px;\n    }\n    .easy-step-p{\n        font-weight: 600;\n        font-size: 10px;\n        line-height: 15.12px;\n        color: #656565;\n    }\n}\n\n\n@media(max-width:374px){\n    .easy-step-h{\n        font-size: 10px !important;\n    }\n    .easy-step-p{\n        font-size: 9px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

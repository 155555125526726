import React from "react";
import "../Header/Header.css";
import img1 from "../../assets/images/Group (3).png"
import img2 from "../../assets/images/Group (4).png"
import img3 from "../../assets/images/icons8-waving-hand-emoji-100 1.png"
import img4 from "../../assets/images/fi_6204186.png"
import img5 from "../../assets/images/icons8-dollar-coin-100 1.png"
import img6 from "../../assets/images/Vector.png"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Header = ({ToggleMenu}) => {
     const { WebCampaignDetails } = useSelector((state) => state.user);
  return (
    <div className="bg-[#1C1C1C] fixed z-10 header-fix">
      <div className="lg:px-[100px] md:px-[60px] px-[20px] lg:py-[28px] md:py-[28px] py-[20px] flex items-center justify-between flex-wrap header-contain">
        <div className="flex items-center" onClick={() => ToggleMenu(false)}>
          <img
            src={img2}
            alt="icon-logo"
            className="h-[40px] w-[27.59px] mr-[10px]"
          />
          <img src={img1} alt="icon-logo" className="h-[26px] w-[168px]" />
        </div>

        <div className="flex items-center header-logo">
          <div className=" bg-[#333333]  px-[15px] rounded-[20px] py-[10px] flex items-center header-coin">
            <img src={img5} alt="coin" className="w-[16px] h-[16px]" />
            <span className="ml-[5px] mr-[10px] text-[#FEFFFA] font-semibold leading-[17.64px] text-[14px] header-span-coin">
              {WebCampaignDetails?.userData?.currentBalance}
            </span>
            <img
              src={img4}
              alt="coin"
              className="w-[26px] h-[26px] header-img-coin"
            />
            <span className="ml-[10px] text-[#FEFFFA] font-semibold leading-[17.64px] text-[14px] header-span-coin">
              <span className="text-[#62CD5D] mr-[2px]">
                {WebCampaignDetails?.userData?.currencySymbol}
              </span>
              {WebCampaignDetails?.userData?.coinConvertBalance}
            </span>
          </div>

          <div className="flex">
            <div className="ml-[20px] mr-[10px] header-name">
              <div className="flex">
                <img
                  src={img3}
                  alt="icon"
                  className="w-[14px] h-[14px] mr-[5px]"
                />
                <span className="text-[14px] font-semibold text-[#FEFFFA]  header-name-user">
                  Hello!
                </span>
              </div>
              <span className="text-[14px] font-semibold text-[#FEFFFA]  header-name-user">
                {WebCampaignDetails?.userData?.userName}
              </span>
            </div>

            <div className="h-[44px] w-[44px] rounded-[100px] lg:ml-[20px] ml-[10px] bg-[#333333] flex justify-center items-center">
              <img src={img6} alt="icon" className="w-[18px] h-[24px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

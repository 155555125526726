import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import ProductBg from "../../assets/images/ProductBg.png";
import img1 from "../../assets/images/Star 9 (1).png";
import img2 from "../../assets/images/image 327.png";
import img3 from "../../assets/images/Medal.png";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setWebCampaignDetails,
} from "../Slice/UserSlice";
import "../../Components/GiveAwayProduct/GiveAwayProduct.css";

const GiveAwayProduct = () => {
  const { WebCampaignDetails } = useSelector((state) => state.user);
  // console.log("pathGetUrl", pathGetUrl)

  const gradientColors = [
    ["#281E1E", "#8E6B6B"],
    ["#281E1E", "#B28F77"],
    ["#1C1C1C", "#949AA7"],
    ["#262626", "#B59DC2"],
    ["#313131", "#8A7552"],
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    // pauseOnHover: true,
    afterChange: (index) => setCurrentSlide(index),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const selectedGradient = gradientColors[currentSlide % gradientColors.length];

  const GiveAwayBg = {
    backgroundImage: `url(${ProductBg})`,
    height: "262px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "315px",
    // marginTop: "100px",
  };

  const GiveAwayStar = {
    backgroundImage: `url(${img1})`,
    height: "78px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "80px",
  };
  const GiveAwayTrophy = {
    backgroundImage: `url(${img2})`,
    height: "96px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "96px",
  };
  const dispatch = useDispatch();

  const URL = process.env.REACT_APP_BASE_API_URL;
  const { campaignName, id } = useParams();

  const fetchgetWebCampaignDetails = async (campaignVersion, campaignId) => {
    const response = await axios.get(
      `${URL}campaign/getWebCampaignDetails/${campaignVersion}/${campaignId}`
    );
    if (response.status === 200) {
      dispatch(setLoading(false));
    }
    dispatch(setWebCampaignDetails(response?.data?.data));
  };

  useEffect(() => {
    if (id && campaignName) {
      // const GetUrl = `http://localhost:3000/${campaignName}/${id}`
      // dispatch(setpathGetUrl(GetUrl))
      fetchgetWebCampaignDetails(campaignName, id);
    }
  }, [id]);

  const getOrdinalSuffix = (num) => {
    if (num === 1) return "st";
    if (num === 2) return "nd";
    if (num === 3) return "rd";
    return "th";
  };

  return (
    <div className=" lg:mx-auto lg:max-w-[1450px] lg:w-[90%]">
      {/* <div className="lg:mb-[50px] md:mb-[40px] mb-[20px]">
        <h1 className="text-[#FFFFFF] lg:text-[50px] text-[18px] font-bold lg:leading-[63px] md:leading-[50px] leading-[22.68px] text-center">
          Excited Giveaway Products
        </h1>
        <p className="!text-[#656565] lg:text-[20px] md:text-[20px] text-[12px] font-medium lg:leading-[25.2px] md:leading-[25px] leading-[15.12px] text-center lg:pt-[9px] md:pt-[9px] pt-[5px]">
          Enter bonus draw for a chance to win
        </p>
      </div> */}

      <div
        style={{
          background: `linear-gradient(${selectedGradient[0]}, ${selectedGradient[1]})`,
        }}
        className="px-[30px] product-sliders"
      >
        <Slider {...settings} className="">
          {WebCampaignDetails?.campaignData?.products.map((card) => (
            <div
              key={card.id}
              style={{
                padding: "20px",
                backgroundColor: "red",
                borderRadius: "10px",
                color: "#000000",
                textAlign: "center",
                height: "400px",
              }}
              className=""
            >
              <div className="lg:mb-[50px] md:mb-[40px] mb-[20px] pt-[20px]">
                <h1 className="text-[#FFFFFF] lg:text-[50px] text-[18px] font-bold lg:leading-[63px] md:leading-[50px] leading-[22.68px] text-center">
                  Bigger Giveaways, Bigger Surprises
                </h1>
                <p className="!text-[#656565] lg:text-[20px] md:text-[20px] text-[12px] font-medium lg:leading-[25.2px] md:leading-[25px] leading-[15.12px] text-center lg:pt-[9px] md:pt-[9px] pt-[5px]">
                  Don’t Miss Out to Win the Biggest Jackpot!
                </p>
              </div>
              <div className="flex justify-center">
                <div
                  className="flex justify-center items-center products-slide-bg"
                  style={GiveAwayBg}
                >
                  {/* <div
                    className="relative top-[-71px] left-[226px] products-slide-trophy"
                    style={GiveAwayTrophy}
                  ></div> */}

                  {/* <div
                    className="relative top-[-67px] left-[-76px] flex justify-center items-center flex-col product-slide-star"
                    style={GiveAwayStar}
                  >
                    <div className="text-[20px] font-bold text-[#101010] product-prizepool">
                      {card?.productPrizePool}
                      <sapn className="relative top-[-5px]">
                        {getOrdinalSuffix(parseInt(card?.productPrizePool))}
                      </sapn>
                    </div>
                    <div className="text-[#101010] text-[10px] font-semibold">
                      prize
                    </div>
                  </div> */}

                  {/* <div className="w-[185px] ">
                    <img
                      src={card?.productImages[0]}
                      alt="giveaway-img"
                      className="w-[185px] relative right-[73px] product-slide-images"
                    />
                  </div> */}

                  <div className="w-[220px] product-img-slide">
                    <img
                      src={card?.productImages[0]}
                      alt="giveaway-img"
                      className="w-[220px] product-slide-images"
                    />
                  </div>
                </div>
                <div
                  className="products-slide-trophy absolute ml-[213px]"
                  style={GiveAwayTrophy}
                ></div>

                <div
                  className=" flex justify-center items-center flex-col product-slide-star absolute mr-[220px] mt-[16px]"
                  style={GiveAwayStar}
                >
                  <div className="text-[20px] font-bold text-[#101010] product-prizepool">
                    {card?.productPrizePool}
                    <sapn className="relative top-[-5px]">
                      {getOrdinalSuffix(parseInt(card?.productPrizePool))}
                    </sapn>
                  </div>
                  <div className="text-[#101010] text-[10px] font-semibold">
                    prize
                  </div>
                </div>
              </div>

              {/* <div
                    className="products-slide-trophy"
                    style={GiveAwayTrophy}
                  ></div> */}

              <div className="flex justify-center relative top-[-52px] slider-product-prize">
                <div className="p-[10px] bg-[#262626] rounded-[12px]  flex items-center overflow-hidden">
                  <img
                    src={img3}
                    alt="win-icon"
                    className="w-[20px] h-[20px]"
                  />
                  <p className="font-bold text-[16px] leading-[20.16px] text-[#FEFFFA] ml-[8px] text-ellipsis whitespace-nowrap">
                    {/* <span>₹</span>  */}
                    {card?.productPrice}
                  </p>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="mb-[20px] w-[315px] pt-[12px] slider-product-name">
                  <div className="p-[10px] bg-[#262626] rounded-[12px] flex justify-center">
                    <p className="font-bold text-[16px] leading-[20.16px] text-[#FEFFFA] text-center ml-[8px]">
                      {card?.productName}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default GiveAwayProduct;

import "./App.css";
import Page from "./Page";
import {
  Route,
  Routes,
} from "react-router-dom";
import FailedPopup from "./Components/PaymtPopup/FailedPopup";
import PhonepePopup from "./Components/PaymtPopup/PhonepePopup";
import NotFoundLaptop from "./Components/NotFound/NotFoundLaptop";
import CashFreePopup from "./Components/PaymtPopup/CashFreePopup";
import ApplePayButton from "./Components/ApplePay/ApplePayButton";

function App() {

  return (
    <div>
      <Routes>
        <Route
          path="*"
          element={<NotFoundLaptop />}
        />

        {/* Payment routes */}
        <Route
          path="/success-paypal/:campaignName/:id"
          element={<PhonepePopup />}
        />
        <Route
          path="/failed-paypal/:campaignName/:id"
          element={<FailedPopup />}
        />
        <Route
          path="/success-cashfree/:campaignName/:id"
          element={<CashFreePopup />}
        />
        <Route path="/applepay" element={<ApplePayButton />} />
        {/* <Route path="/applepayBtn" element={<ApplePayBtnpage />} /> */}

        {/* <Route
          path="/success-payment/:campaignName/:id"
          element={<PhonepePopup />}
        />
        <Route
          path="/failed-payment/:campaignName/:id"
          element={<FailedPopup />}
        /> */}
        <Route path="/:campaignName/:id" element={<Page />} />

        {/* {isMobile ? (
          <Route path="/:campaignName/:id" element={<Page />} />
        ) : (
          <Route
            path="/laptop/:campaignName/:id"
            element={<NotFoundLaptop />}
          />
        )} */}
        {/* laptop route path */}
        {/* <Route path="/laptop/:campaignName/:id" element={<NotFoundLaptop />} /> */}
      </Routes>
      {/* </NetworkLost> */}
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 390px) {
  .product-sliders {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 370px) {
  .products-slide-bg {
    width: 270px !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
  }
  .products-slide-trophy {
    height: 66px !important;
    width: 70px !important;
    margin-left: 206px !important;
    margin-top: 25px !important;
  }
  .product-slide-star {
    height: 61px !important;
    width: 60px !important;
    margin-top: 34px !important;
  }
  .product-prizepool {
    font-size: 13px !important;
  }
  .product-slide-images {
    height: 140px !important;
    right: 68px !important;
  }
  .slider-product-name {
    width: 270px !important;
  }
  .slider-product-prize{
    top: -66px !important;
  }
  .product-img-slide{
    width: 170px !important;
  }
}

@media (max-width: 348px) {
  /* .products-slide-bg {
    width: 250px !important;
  } */
  .slider-product-name {
    width: 250px !important;
  }
  .products-slide-trophy {
    height: 66px !important;
    /* left: 198px !important; */
  }
  .product-slide-images {
    right: 56px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/GiveAwayProduct/GiveAwayProduct.css"],"names":[],"mappings":"AAAA;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,uBAAuB;IACvB,mCAAmC;IACnC,uCAAuC;EACzC;EACA;IACE,uBAAuB;IACvB,sBAAsB;IACtB,6BAA6B;IAC7B,2BAA2B;EAC7B;EACA;IACE,uBAAuB;IACvB,sBAAsB;IACtB,2BAA2B;EAC7B;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,wBAAwB;IACxB,sBAAsB;EACxB;EACA;IACE,uBAAuB;EACzB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE;;KAEG;EACH;IACE,uBAAuB;EACzB;EACA;IACE,uBAAuB;IACvB,4BAA4B;EAC9B;EACA;IACE,sBAAsB;EACxB;AACF","sourcesContent":["@media (max-width: 390px) {\n  .product-sliders {\n    padding-left: 20px;\n    padding-right: 20px;\n  }\n}\n\n@media (max-width: 370px) {\n  .products-slide-bg {\n    width: 270px !important;\n    background-size: contain !important;\n    background-repeat: no-repeat !important;\n  }\n  .products-slide-trophy {\n    height: 66px !important;\n    width: 70px !important;\n    margin-left: 206px !important;\n    margin-top: 25px !important;\n  }\n  .product-slide-star {\n    height: 61px !important;\n    width: 60px !important;\n    margin-top: 34px !important;\n  }\n  .product-prizepool {\n    font-size: 13px !important;\n  }\n  .product-slide-images {\n    height: 140px !important;\n    right: 68px !important;\n  }\n  .slider-product-name {\n    width: 270px !important;\n  }\n  .slider-product-prize{\n    top: -66px !important;\n  }\n  .product-img-slide{\n    width: 170px !important;\n  }\n}\n\n@media (max-width: 348px) {\n  /* .products-slide-bg {\n    width: 250px !important;\n  } */\n  .slider-product-name {\n    width: 250px !important;\n  }\n  .products-slide-trophy {\n    height: 66px !important;\n    /* left: 198px !important; */\n  }\n  .product-slide-images {\n    right: 56px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import img1 from "../../assets/images/Iconpopup.svg";
import img2 from "../../assets/images/fi_9426995.png";
import { useNavigate, useParams } from "react-router-dom";

const FailedPopup = () => {
  const navigate = useNavigate();
  const { campaignName, id } = useParams();
  const routes = {
    success: "/success-payment",
    failed: "/failed-payment",
    campaign: `/${campaignName}/${id}`,
  };

  const handleDoneClick = () => {
    navigate(routes.campaign);
  };

  return (
    <div className="flex h-[100vh] justify-center items-center bg-[#1C1C1C]">
      <div className="border-[1px] border-[#EA0000] rounded-[20px] w-[350px] failed-popup">
        <div
          className="flex justify-end mr-[8px] mt-[5px]"
          onClick={handleDoneClick}
        >
          <img src={img2} alt="close-img" className="w-[30px] h-[30px]" />
        </div>
        <div className="px-[28px]">
          <div className="pt-[70px] flex justify-center items-center">
            <img src={img1} alt="popup-img" className="w-[172px] h-[163px]" />
          </div>

          <div className="flex justify-center items-center">
            <p className="text-center pt-[20px] text-[#656565] text-[12px] leading-[15px] font-semibold w-[200px] ">
              Oops! Something went terribly wrong here
            </p>
          </div>
          <p className="text-center pt-[10px] text-[#EA0000] text-[25px] leading-[31px] font-bold">
            Failed
          </p>
          <div className="pt-[90px]">
            <button
              onClick={handleDoneClick}
              className="py-[20px] bg-[#333333] text-[#EA0000] text-[16px] font-bold rounded-[20px] mt-[18px] mb-[30px] w-full"
            >
              Please Try Again
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FailedPopup;

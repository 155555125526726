import React, { useEffect, useLayoutEffect, useState } from "react";
import Header from "./Components/Header/Header";
import GiveAwayBattle from "./Components/GiveAwayBattle/GiveAwayBattle";
import EasyStep from "./Components/EasyStep/EasyStep";
import Lottery from "./Components/Lottery/Lottery";
import GiveAwayProduct from "./Components/GiveAwayProduct/GiveAwayProduct";
import IMG from "../src/assets/images/Background.png"
import IMG2 from "../src/assets/images/Background1.png"
import NotFoundLaptop from "./Components/NotFound/NotFoundLaptop";
import GiveAwayWinner from "./Components/GiveAwayWinner/GiveAwayWinner";
import GiveAwayPopup from "./Components/GiveAwayWinner/GiveAwayPopup";
import SuccessPopup from "./Components/PaymtPopup/SuccessPopup";
import FailedPopup from "./Components/PaymtPopup/FailedPopup";
import { useLocation } from "react-router-dom";

const Page = () => {
  const pageBackground = {
    backgroundImage: `url(${IMG2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    color: "white",
    paddingTop:"84px"
  };

  // const loaction = useLocation()
  // console.log("loaction", loaction);
   
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    const [winnerList, setWinnerList] = useState(false);
  const [showPopup, setshowPopup] = useState(false);


    const checkViewport = () => {
      setViewportSize(window.innerWidth);
    };

    useLayoutEffect(() => {
      checkViewport();
      window.addEventListener("resize", checkViewport);

      return () => {
        window.removeEventListener("resize", checkViewport);
      };
    }, []);

    const isScreenTooSmall = viewportSize <= 319;
    const isScreenTooLarge = viewportSize > 720;

    if (isScreenTooSmall || isScreenTooLarge) {
      return (
        <div>
          <NotFoundLaptop />
        </div>
      );
    }

  const ToggleMenu = (name) => {
    setWinnerList(name)
  }


  return (
    <div>
      <div>
        <Header ToggleMenu={ToggleMenu} />
      </div>
      {winnerList ? (
        <div className="">
          <GiveAwayWinner />
        </div>
      ) : (
        <>
          {/* {winnerList ? (
            <GiveAwayPopup />
          ) : ( */}
          <>
            <div style={pageBackground} className="home-page-fix">
              <div>
                <GiveAwayBattle ToggleMenu={ToggleMenu} />
              </div>
              <div>
                {/* <SuccessPopup /> */}
              </div>
              {/* <FailedPopup /> */}
              
              <div className="backdrop-brightness-50">
                <EasyStep />
              </div>
              <div>
                <Lottery />
              </div>
              <div>
                <GiveAwayProduct />
              </div>
            </div>
          </>
          {/* )} */}
        </>
      )}
    </div>
  );
};

export default Page;

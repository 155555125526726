import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IMG10 from "../../assets/images/image 328.png";
import img3 from "../../assets/images/Medal.png";
import IMG from "../../assets/images/Background1.png";

import IMG1 from "../../assets/images/Vector123.png";
import Indianflag from "../../assets/images/India.png";
import IMG11 from "../../assets/images/Icon.png";
import IMG12 from "../../assets/images/Icon (1).png";
import IMG13 from "../../assets/images/pngfind.com-buttons-png-29055.png";
import ProductBg from "../../assets/images/ProductBg.png";
import img1 from "../../assets/images/Star 9 (1).png";
import img2 from "../../assets/images/image 327.png";
import { setShowPopup } from "../Slice/UserSlice";
import "../GiveAwayWinner/GiveAwayWiner.css";
import Modal from "react-modal";

const GiveAwayPopup = ({ isOpen, onRequestClose }) => {
  const getAvatarFromUserName = (userName) => {
    if (!userName) return "";
    const firstChar = userName.charAt(0).toUpperCase();
        const colorMapping = {
          A: "#FF5733",
          B: "#33FF57",
          C: "#3357FF",
          D: "#F1C40F",
          E: "#E67E22",
          F: "#2ECC71",
          G: "#3498DB",
          H: "#9B59B6",
          I: "#1ABC9C",
          J: "#34495E",
          K: "#E74C3C",
          L: "#8E44AD",
          M: "#2C3E50",
          N: "#16A085",
          O: "#27AE60",
          P: "#2980B9",
          Q: "#8E44AD",
          R: "#D35400",
          S: "#C0392B",
          T: "#F39C12",
          U: "#C0C0C0",
          V: "#7D3C98",
          W: "#F7DC6F",
          X: "#D5DBDB",
          Y: "#F5B041",
          Z: "#AAB7B8",
        };

        const backgroundColor = colorMapping[firstChar] || "#BDC3C7"; 

        return {
          backgroundColor,
          text: firstChar,
        }; 
  };
  const GiveAwayWinnerStyle2 = {
    backgroundImage: `url(${IMG})`,
    // backgroundSize: "cover",
    backgroundPosition: "center",
    // height: "100vh",
    paddingTop: "20px",
    // overflow: "auto",
  };

  const GiveAwayWinnerStyle3 = {
    backgroundImage: `url(${IMG1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "400px",
  };

  const GiveProductBg = {
    backgroundImage: `url(${ProductBg})`,
    height: "262px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "315px",
  };
  const GiveAwayStar = {
    backgroundImage: `url(${img1})`,
    height: "73px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "73px",
  };
  const GiveAwayTrophy = {
    backgroundImage: `url(${img2})`,
    height: "96px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "96px",
  };

  const GiveAwayBg = {
    backgroundImage: `url(${ProductBg})`,
    height: "262px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "315px",
    // marginTop: "100px",
  };

  const gradientColors = [["#281E1E", "#8E6B6B"]];

  const { winnnerProfile } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const getOrdinalSuffix = (num) => {
    if (num === 1) return "st";
    if (num === 2) return "nd";
    if (num === 3) return "rd";
    return "th";
  };

  //   useEffect(() => {
  //     const timer = setTimeout(() => {
  //       dispatch(setShowPopup(true));
  //     }, 3000);

  //     return () => clearTimeout(timer);
  //   }, []);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="GiveAway Popup"
      >
        <div className="popup-bg" style={GiveAwayWinnerStyle2}>
          <div style={GiveAwayWinnerStyle3}>
            <div className="pt-[10px] rounded-[20px]">
              <div className="p-[20px]">
                <div className="flex justify-end">
                  <img
                    src={IMG13}
                    alt="close-btn-img"
                    className="w-[22px] h-[22px]"
                    onClick={() => dispatch(setShowPopup(false))}
                  />
                </div>

                <div>
                  <div className="flex justify-center">
                    <div
                      className="flex justify-center items-center relative left-[50px]"
                      style={{
                        backgroundColor: getAvatarFromUserName(
                          winnnerProfile.userName
                        ).backgroundColor,
                        width: "76.2px",
                        height: "76.2px",
                        borderRadius: "50%",
                      }}
                    >
                      <p className="text-[#FEFFFA] text-[24px] font-semibold">
                        {getAvatarFromUserName(winnnerProfile.userName).text}{" "}
                      </p>
                    </div>
                    <img
                      src={IMG10}
                      alt="img-win"
                      className="w-[83px] h-[83px] relative top-[-56px] left-[-30px]"
                    />

                    <div className="relative top-[57px] left-[-50px]">
                      <img src={Indianflag} alt="indian-flag" />
                    </div>
                  </div>

                  <div className="flex justify-center items-center flex-col pt-[5px]">
                    <h1 className="text-[21.77px] text-[#FFFFFF] font-medium leading-[32.66px]">
                      {winnnerProfile.userName}
                    </h1>
                    <p className="leading-[15.12px] text-[#FFFFFF] text-[12px] font-semibold pt-[5px]">
                      Congrats! You've Been Selected For The Grand Prize
                    </p>
                  </div>
                </div>

                <div className="bg-[#101010] py-[17px] px-[60px] rounded-[20px] mt-[20px] flex justify-center popup-profile-padding">
                  <div className=" flex items-center flex-col ">
                    <img
                      src={IMG11}
                      alt="img-star"
                      className="w-[24px] h-[24px]"
                    />
                    <h4 className="text-[#656565] text-[12px] font-semibold pt-[4px] popup-complete">
                      Complete
                    </h4>
                    <p className="font-medium leading-[16px] text-[16px] text-[#FFFFFF] pt-[4px]">
                      {winnnerProfile.totalActivitiesCompleted}
                    </p>
                  </div>
                  <div className="border-r-[1px] border-[#FEFFFA] pl-[40px]"></div>
                  <div className=" flex items-center flex-col pl-[40px]">
                    <img
                      src={IMG12}
                      alt="img-star"
                      className="w-[24px] h-[24px]"
                    />
                    <h4 className="text-[#656565] text-[12px] font-semibold pt-[4px] popup-winner-rank">
                      Winner Rank
                    </h4>
                    <p className="font-medium leading-[16px] text-[16px] text-[#FFFFFF] pt-[4px] winner-profile-rank">
                      {winnnerProfile.winnerRank}
                      {getOrdinalSuffix(parseInt(winnnerProfile.winnerRank))}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                background: `linear-gradient(${gradientColors[0][0]}, ${gradientColors[0][1]})`,
              }}
              className="py-[20px] px-[30px] h-[380px] flex justify-center"
            >
              <div className="flex justify-center">
                <div
                  className="flex justify-center items-center product-img-bg"
                  style={GiveAwayBg}
                >
                  <div className="w-[220px] popup-img-product">
                    <img
                      src={winnnerProfile?.productDetails?.productImages[0]}
                      alt="giveaway-img"
                      className="w-[220px] products-img-slider"
                    />
                  </div>
                </div>

                <div
                  className=" product-img-trophy absolute ml-[213px]"
                  style={GiveAwayTrophy}
                ></div>

                <div
                  className="absolute flex justify-center items-center flex-col product-img-star mr-[220px] mt-[16px]"
                  style={GiveAwayStar}
                >
                  <div className="text-[20px] font-bold text-[#101010] product-prizepool">
                    {winnnerProfile.winnerRank}
                    {getOrdinalSuffix(parseInt(winnnerProfile.winnerRank))}
                  </div>
                  <div className="text-[#101010] text-[10px] font-semibold">
                    prize
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center relative top-[-150px] product-price">
              <div className="p-[10px] bg-[#262626] rounded-[12px]  flex items-center overflow-hidden">
                <img src={img3} alt="win-icon" className="w-[20px] h-[20px]" />
                <p className="font-bold text-[16px] leading-[20.16px] text-[#FEFFFA] ml-[8px] text-ellipsis whitespace-nowrap">
                  <span>₹</span> {winnnerProfile?.productDetails?.productPrice}
                </p>
              </div>
            </div>

            <div className="flex justify-center  relative top-[-119px] products-img-name">
              <div className="w-[315px] mb-[20px] product-name">
                <div className="p-[10px] bg-[#262626] rounded-[12px] flex justify-center">
                  <p className="font-bold text-[16px] leading-[20.16px] text-[#FEFFFA] text-center ml-[8px]">
                    {winnnerProfile?.productDetails?.productName}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GiveAwayPopup;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media(max-width:374px){
    .lottery-name{
        font-size: 10px !important;
    }
    .lottery-description{
        font-size: 10px !important;
    }
}

@media(max-width:389px){
    .payment-popup{
        width: 290px;
        left: -10px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Lottery/Lottery.css"],"names":[],"mappings":"AAAA;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,0BAA0B;IAC9B;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,sBAAsB;IAC1B;AACJ","sourcesContent":["@media(max-width:374px){\n    .lottery-name{\n        font-size: 10px !important;\n    }\n    .lottery-description{\n        font-size: 10px !important;\n    }\n}\n\n@media(max-width:389px){\n    .payment-popup{\n        width: 290px;\n        left: -10px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import IMG from "../../assets/images/pagenotfountbg.png";
import IMG1 from "../../assets/images/bgdot1.png";
import IMG2 from "../../assets/images/1.png";
import IMG3 from "../../assets/images/Center.png";
import IMG4 from "../../assets/images/Playstore.png";
import IMG5 from "../../assets/images/social.png";
import IMG6 from "../../assets/images/Apple.png";
import IMG7 from "../../assets/images/Google.png";
import "../../Components/NotFound/NotFoundLaptop.css";

const NotFoundLaptop = () => {
  const pageBackground = {
    backgroundImage: `url(${IMG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    overflow: "auto",
  };
  const mobliebg = {
    backgroundImage: `url(${IMG1})`,
    backgroundSize: "cover",
    // width: "1440px",
    width: "85%",
    height: "100vh",
    overflow:"auto"
  };

  const socialbg = {
    backgroundImage: `url(${IMG5})`,
  };

  return (
    <div className="">
      <div style={pageBackground}>
        <div className="flex justify-center">
          <div style={mobliebg} className="mobile-bg">
            <div className="pt-[30px] flex items-center flex-col mobile-padding-top-h">
              <div>
                <h1 className="font-bold text-[60px] leading-[75.6px] text-[#FAFAFA] mobile-access-h">
                  Access on Mobile Only!!!
                </h1>
              </div>

              <div className="pt-[20px] w-[756px] mobile-access-p-width">
                <p className="font-medium text-[16px] text-[#FAFAFA] leading-[20.16px] text-center mobile-access-p">
                  Access this better view on your mobile device only. Our
                  mobile-first features are designed to enhance your on-the-go
                  experience. Switch to your smartphone to explore more!
                </p>
              </div>
            </div>

            <div className="">
              <div className="pt-[20px] flex justify-center items-center grabit-mobile-app-img">
                <div className="mobile-grabit-img">
                  <img
                    src={IMG2}
                    alt="grabit-img"
                    className="w-[174px] h-[353px] mr-[61px] mobile-access-m"
                  />
                </div>
                <div>
                  <img
                    src={IMG3}
                    alt="grabit-img"
                    className="w-[220px] h-[461px] mr-[61px] mobile-access-m"
                  />
                </div>
                <div className="mobile-grabit-img">
                  <img
                    src={IMG2}
                    alt="grabit-img"
                    className="w-[174px] h-[353px]"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-center pt-[30px] moblie-download-btn">
              <button className=" text-[white] text-[30px] leading-[22.68px] font-bold rounded-[20px] mobile-download-app">
                Download Our App
              </button>
            </div>

            <div className="flex justify-center paystore-icons h-[67px] pt-[20px] img-playstore">
              <div>
                <a
                  href="https://apps.apple.com/in/app/grabituser/id6480311561"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={IMG6}
                    alt="playstore-icon"
                    className="relative left-[60px] applestore-img-icon"
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.corenettech.grabituser&pli=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={IMG7}
                    alt="playstore-icon"
                    className="relative right-[60px] playstore-img-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundLaptop;

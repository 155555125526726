import React, { useState } from "react";
import Modal from "react-modal";

const CommanPopup = ({
  isOpen,
  onClose,
  title,
  imageSrc,
  label,
  placeholder,
  submitButtonText,
  cancelButtonText,
  footerText,
  onSubmit,
  inputValue, 
  setInputValue,
}) => {
  const [localInputValue, setLocalInputValue] = useState(inputValue || "");

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (setInputValue) {
      setInputValue(value);
    } else {
      setLocalInputValue(value);
    }
  };
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Success Modal"
        className="modalContent"
        overlayClassName="modalOverlay"
        shouldCloseOnOverlayClick={true}
      >
        <div className="pt-[117px] p-[20px]">
          {imageSrc && (
            <div className="flex justify-center items-center">
              <img src={imageSrc} alt="Icon" className="w-[111px] h-[86px]" />
            </div>
          )}
          {title && (
            <div className="pt-[10px] flex justify-center items-center">
              <h3 className="text-[#62CD5D] text-[25px] font-bold leading-[31px]">
                {title}
              </h3>
            </div>
          )}
          {label && (
            <div className="pt-[44px]">
              <label className="text-[16px] text-[#FFFFFF] font-medium">
                {label} <span className="text-[green]">*</span>
              </label>
              <input
                type="text"
                value={setInputValue ? inputValue : localInputValue}
                onChange={handleInputChange}
                placeholder={placeholder || "ex. ’askgrabitapp’"}
                className="w-full rounded-[20px] p-[10px] bg-[#333333] mt-[10px]"
              />
            </div>
          )}
          <div className="pt-[20px]">
            <div className="flex justify-between items-center">
              <div
                className="w-[48%] bg-[#62CD5D] rounded-[20px] flex justify-center items-center"
                onClick={onSubmit}
              >
                <button className="text-[#101010] p-[10px]">
                  {submitButtonText || "Submit"}
                </button>
              </div>
              <div
                className="w-[48%] bg-[#333333] rounded-[20px] flex justify-center items-center"
                onClick={onClose}
              >
                <button className="text-[#62CD5D] p-[10px]">
                  {cancelButtonText || "Cancel"}
                </button>
              </div>
            </div>
          </div>
          {footerText && (
            <div className="pt-[20px] flex justify-center items-center">
              <p className="font-semibold text-[12px] leading-[15px] text-[#FFFFFF]">
                {footerText}
              </p>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CommanPopup;

import axios from "axios";
import React, { useState } from "react";

const ApplePayButton = () => {
  const [applePaySession, setApplePaySession] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const beginApplePaySession = () => {
    // Prevent starting a new session if one is already active
    if (applePaySession) {
      console.error("An Apple Pay session is already active.");
      return;
    }

    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      const paymentRequest = {
        countryCode: "US",
        currencyCode: "USD",
        supportedNetworks: ["amex", "discover", "masterCard", "visa"],
        merchantCapabilities: ["supports3DS"],
        total: {
          label: "Your Merchant",
          amount: "10.00",
        },
      };

      try {
        const session = new window.ApplePaySession(3, paymentRequest);
        setApplePaySession(session); // Track the session

        session.onvalidatemerchant = async (event) => {
          try {
            console.log("I am Inside onvalidatemerchant and IT's Started");
            const response = await axios.post("https://devapi.grabit.org.in/api/web/business/v1/applePay/validateMerchant", {
              validationURL: event.validationURL,
            });

            console.log("I am Inside onvalidatemerchant and IT's Ended with data", response.data.data);

            const merchantSession = await response.data.data;
            session.completeMerchantValidation(merchantSession);
          } catch (error) {
            console.error("Merchant validation error:", error);
            session.abort();
            setApplePaySession(null); // Reset session on error
          }
        };

        session.onpaymentauthorized = async (event) => {
          setIsProcessing(true);
          try {
            console.log("I am Inside onpaymentauthorized and IT's Started");
            const response = await axios.post("https://devapi.grabit.org.in/api/web/business/v1/applePay/processPayment", {
              payment: event.payment,
            });
            console.log("I am Inside onpaymentauthorized and IT's Ended with data", response.data);
            if (response.data.success) {
              session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
            } else {
              session.completePayment(window.ApplePaySession.STATUS_FAILURE);
            }
          } catch (error) {
            console.error("Payment processing error:", error);
            session.completePayment(window.ApplePaySession.STATUS_FAILURE);
          } finally {
            setIsProcessing(false);
            setApplePaySession(null); // Reset session after payment attempt
          }
        };

        session.oncancel = (err) => {
          console.log("I am Inside oncancel and IT's Started");
          console.log(err);

          // console.log("👊 ------------------------------------👊")
          // console.log("👊 ~ beginApplePaySession ~ err:", err)
          // console.log("👊 ------------------------------------👊")
          console.log("Apple Pay session canceled.");
          setApplePaySession(null); // Reset session when canceled
        };

        session.begin();
      } catch (error) {
        console.error("Apple Pay session initialization error:", error);
        alert("Failed to start Apple Pay session.");
        setApplePaySession(null); // Reset session if initialization fails
      }
    } else {
      console.error("Apple Pay is not available on this device/browser");
      alert("Apple Pay is not available on this device/browser");
    }
  };

  return <button
    onClick={beginApplePaySession}
    disabled={isProcessing || applePaySession}
  >
    {isProcessing ? 'Processing...' : 'Pay with Apple Pay'}
  </button>;
};

export default ApplePayButton;

import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    WebCampaignDetails: [],
    winnnerProfile: [],
    showPopup: false,
    Loading: true,
    modalIsOpen: false,
    pathGetUrl: "",
    payPalPaymentId:""
  },
  reducers: {
    setWebCampaignDetails: (state, action) => {
      state.WebCampaignDetails = action.payload;
    },
    setwinnerProfile: (state, action) => {
      state.winnnerProfile = action.payload;
    },
    setShowPopup: (state, action) => {
      state.showPopup = action.payload;
    },
    setLoading: (state, action) => {
      state.Loading = action.payload;
    },
    setModalIsOpen: (state, action) => {
      state.modalIsOpen = action.payload;
    },
    setpathGetUrl: (state, action) => {
      state.pathGetUrl = action.payload;
    },
    setpayPalPaymentId: (state, action) => {
      state.payPalPaymentId = action.payload;
    },
  },
});

export const {
  setWebCampaignDetails,
  setwinnerProfile,
  setShowPopup,
  setLoading,
  setModalIsOpen,
  setpathGetUrl,
  setpayPalPaymentId,
} = userSlice.actions;

export default userSlice.reducer;

  import React from "react";
  import "../EasyStep/EasyStep.css";
  import img1 from "../../assets/images/image351.png"
  import img2 from "../../assets/images/image (4).png"
  import img3 from "../../assets/images/image (5).png"
  import img4 from "../../assets/images/image (3).png"

  const EasyStep = () => {
    const steps = [
      {
        imgSrc: img1,
        title: "Explore Fun Activities",
        description: "Browse through our exciting range of activities.",
      },
      {
        imgSrc: img2,
        title: "Complete Challenges",
        description: "Finish tasks and challenges to unlock rewards.",
      },
      {
        imgSrc: img3,
        title: "Keep Calm, Prize is Coming",
        description: "Relax - your prize is just around the corner.",
      },
    ];

    return (
      <div className="container">
        <div className="lg:py-[100px] md:py-[50px] easy-step-box">
          <div>
            <h1 className="font-bold text-[#FFFFFF] lg:text-[50px] md:text-[35px] leading-[63px] easy-step-started">
              How to get started
            </h1>
            <p className="font-medium text-[20px] leading-[25.2px] text-[#ffffff] pt-[9px] three-easy-step">
              Follow these 3 easy steps!
            </p>
          </div>

          <div className="flex items-stretch justify-between lg:pt-[50px] md:pt-[20px] pt-[20px]">
            <div className="w-[49%] step-box-contain">
              {steps.map((step, index) => (
                <div
                  key={index}
                  className="bg-[#1C1C1C] lg:p-[30px] md:p-[10px] p-[10px] rounded-[20px] flex items-center mb-[20px]"
                >
                  <img
                    src={step.imgSrc}
                    alt={step.title}
                    className="lg:w-[130px] md:w-[80px] w-[80px] lg:h-[130px] md:h-[80px] h-[80px]"
                  />
                  <div className="ml-[20px]">
                    <h3 className="font-bold text-[#FEFFFA] text-[25px] leading-[31.5px] easy-step-h">
                      {step.title}
                    </h3>
                    <p className="text-[#656565] text-[12px] font-semibold leading-[15.12px] lg:mt-[5px] md:mt-[10px] mt-[10px] easy-step-p">
                      {step.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className=" h-full w-[49%] easy-img-step">
              <img src={img4} alt="img-gift" className="w-full h-[610px]" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  export default EasyStep;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import "../../Components/Lottery/Lottery.css";
import IMG13 from "../../assets/images/pngfind.com-buttons-png-29055.png";
import IMG from "../../assets/images/RazorPay.svg";
import IMG2 from "../../assets/images/PhonePe.svg";
import intagram from "../../assets/images/Group.png";
import googleImg from "../../assets/images/most_entertaining_illustration.png";
import axios from "axios";
import SuccessPopup from "../PaymtPopup/SuccessPopup";
import FailedPopup from "../PaymtPopup/FailedPopup";
import { useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Modal from "react-modal";
import CommanPopup from "../PaymtPopup/CommanPopup";
import { setpayPalPaymentId } from "../Slice/UserSlice";

const Lottery = () => {
  const { WebCampaignDetails, pathGetUrl, payPalPaymentId } = useSelector(
    (state) => state.user
  );
  // console.log("WebCampaignDetails", WebCampaignDetails.userData.countryCode);
  const [inputValue, setInputValue] = useState("");
  // console.log("pathGetUrlOOOOOOOOOOOOOOOOOOOOOOO", pathGetUrl);
  const navigate = useNavigate();
  const popupRef = useRef(null);
  const dispatch = useDispatch();
  const { error, isLoading, Razorpay } = useRazorpay();

  const URL = process.env.REACT_APP_BASE_API_URL;
  const [showPopup, setShowPopup] = useState(false);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [isSuccessPopupOpen, setSuccessPopupOpen] = useState(false);
  const [intagramPopupShow, setintagramPopupShow] = useState(false);
  const [googlePopupShow, setgooglePopupShow] = useState(false);
  const [isFaildPopupOpen, setFaildPopupOpen] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({ id: "", amount: 0 });

  // const [payPalPaymentId, setpayPalPaymentId] = useState("");

  //  console.log("rozarPayData", rozarPayData)

  const [amount, setAmount] = useState(99);

  const handlePaymentRozarpay = async () => {
    const amountInPaise = amount;
    try {
      const response = await axios.post(`${URL}campaign/createRazorpayOrder`, {
        amount: amountInPaise,
        campaignId: WebCampaignDetails?.campaignData?._id,
        userId: WebCampaignDetails?.userData?.userId,
      });
      // console.log("response", response.data.data);
      // if (response.data && response.data.data && response.data.data.order_id) {
      const options = {
        amount: response.data.data.response.amount,
        key: "rzp_test_CbF0ZmK3zWaLnd",
        currency: "INR",
        name: "Grabit",
        description: "Test Transaction",
        order_id: response.data.data.response.id,
        handler: async function (response) {
          const res = {
            razorpay_order_id: response?.razorpay_order_id,
            razorpay_payment_id: response?.razorpay_payment_id,
            razorpay_signature: response?.razorpay_signature,
          };
          try {
            const verificationResponse = await axios.post(
              `${URL}campaign/userVerifyPayment`,
              {
                campaignId: WebCampaignDetails?.campaignData?._id,
                userId: WebCampaignDetails?.userData?.userId,
                paymentId: res.razorpay_payment_id,
                paymentGateway: "razorpay",
              }
            );
            if (verificationResponse.status === 200) {
              setIsPaymentSuccessful(true);
              setShowPopup(false);
              setSuccessPopupOpen(true);
              setPaymentInfo({
                id: res.razorpay_payment_id,
                amount: amountInPaise,
              });
              localStorage.setItem("paymentSuccessful", "true");
            } else {
              setFaildPopupOpen(true);
            }

            // console.log("verificationResponse>>>>>>", verificationResponse)
          } catch (verificationError) {
            console.error("Error verifying payment:", verificationError);
          }
          console.log("Transaction:", res);
        },

        prefill: {
          name: "Customer Name",
          email: "customer@example.com",
          contact: "123-456-7890",
        },
        notes: {
          address: "note value",
        },
        theme: {
          color: "#F37254",
        },
      };

      const razorpayInstance = new Razorpay(options);
      razorpayInstance.open();

      // }
      //  else {
      //   console.error("Invalid response from server:", response.data);
      // }
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
    }
  };

  useEffect(() => {
    const paymentStatus = localStorage.getItem("paymentSuccessful");
    if (paymentStatus === "true") {
      setIsPaymentSuccessful(true);
    }
  }, []);

  const handleReedeemPrize = () => {
    console.log("handleReedeemPrize", handleReedeemPrize);
  };

  // const [buttonText, setButtonText] = useState("To Complete");
  // const handleInstagram = () => {
  //   // console.log("handleInstagram", handleInstagram);
  //       setintagramPopupShow(true);
  //   //  setButtonText("Verify");
  //   //  window.location.href =
  //   //    "https://www.instagram.com/manali_jemi_motiwala/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D#";

  // };

  const [showVerificationButton, setShowVerificationButton] = useState(false);
  const handleInstagram = () => {
    const paymentWindow = window.open("https://www.instagram.com");

    const interval = setInterval(() => {
      if (paymentWindow.closed) {
        clearInterval(interval);
        setIsPaymentSuccessful(true);
        setShowVerificationButton(true);
      }
    }, 1000);
  };

  // console.log(
  //   "WebCampaignDetails",
  //   WebCampaignDetails.campaignData.activityList.map((data) => data.activityId)
  // );

  const [intagramID, setintagramID] = useState("");

  // console.log("activityIntagramId", activityIntagramId);
  // console.log("inputValue", inputValue);
  const handleVerify = () => {
    setintagramPopupShow(true);
    setInputValue("");
  };

  const handleInvitefriend = () => {
    console.log("handleInvitefriend", handleInvitefriend);
  };

  const handleGoogleReview = () => {
    setgooglePopupShow(true);
  };

  const togglePopup = () => {
    setShowPopup((prev) => !prev);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const handlePhonepePayment = async () => {
    try {
      const response = await axios.post(`${URL}/campaign/createPhonePeOrder`, {
        amount: "99",
        campaignId: WebCampaignDetails?.campaignData?._id,
        userId: WebCampaignDetails?.userData?.userId,
        UrlPath: pathGetUrl,
      });
      // console.log("response", response);
      if (response.status === 200) {
        const redirectUrl =
          response.data.data.data.instrumentResponse.redirectInfo.url;
        // dispatch(setpathGetUrl(window.location.pathname))
        window.location.href = redirectUrl;
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
    }
  };

  const handleCashfreePayment = () => {};


  const handlePaypalPayment = () => {};

  // const createOrder = (data, actions) => {
  //   return actions.order.create({
  //     purchase_units: [
  //       {
  //         amount: {
  //           value: "10.00",
  //         },
  //       },
  //     ],
  //   });
  // };

  // const onApprove = (data, actions) => {
  //   return actions.order.capture().then((details) => {
  //     alert("Transaction completed by " + details.payer.name.given_name);
  //   });
  // };



  // const ipApiResponse = await fetch(
  //   "https://api.allorigins.win/get?url=" +
  //     encodeURIComponent("http://ip-api.com/json")
  // );
  // const ipData = await ipApiResponse.json();
  // const countryInfo = JSON.parse(ipData.contents);
  // console.log("Country Info:", countryInfo);
  
  // const createOrder = async (data, actions) => {
  //   try {
  //     // const orderData = await actions.order.create({
  //     //   intent: "CAPTURE",
  //     //   purchase_units: [
  //     //     {
  //     //       amount: {
  //     //         currency_code: "USD",
  //     //         value: 5,
  //     //       },
  //     //     },
  //     //   ],
  //     // });
  //     const orderData = await actions.order.create({
  //       intent: "sale",
  //       payer: {
  //         payment_method: "paypal",
  //       },
  //       redirect_urls: {
  //         return_url: "", 
  //         cancel_url: "",
  //       },
  //       transactions: [
  //         {
  //           amount: {
  //             currency: "USD",
  //             total: 5, 
  //           },
  //           description: `Payment for campaign ${WebCampaignDetails?.campaignData?._id}`,
  //           custom: JSON.stringify({
  //             userId: WebCampaignDetails?.userData?.userId,
  //             campaignId: WebCampaignDetails?.campaignData?._id,
  //           }),
  //         },
  //       ],
  //     });

  //     console.log("PayPal Order created:", orderData);

  //     // const orderResponse = await axios.post(
  //     //   `${URL}campaign/createPayPalOrder`,
  //     //   {
  //     //     amount: 5,
  //     //     campaignId: WebCampaignDetails?.campaignData?._id,
  //     //     userId: WebCampaignDetails?.userData?.userId,
  //     //     name: WebCampaignDetails.userData.userName,
  //     //   }
  //     // );
  //     // console.log("API Response", orderResponse);

  //     // if (orderResponse.status === 200) {
  //     //   dispatch(setpayPalPaymentId(orderResponse.data.data.paymentId));
  //     // } else {
  //     //   console.error("Failed to create PayPal order on the server");
  //     //   return;
  //     // }

  //     return orderData;
  //   } catch (error) {
  //     console.error("Error creating PayPal order:", error);
  //     return;
  //   }
  // };

  const createOrder = async (data, actions) => {
    try {
      const orderData = await actions.order.create({
        intent: "CAPTURE", 
        purchase_units: [
          {
            amount: {
              currency_code: "USD", 
              value: 5,
            },
            // description: `Payment for campaign ${WebCampaignDetails?.campaignData?._id}`,
            // custom: JSON.stringify({
            //   userId: WebCampaignDetails?.userData?.userId,
            //   campaignId: WebCampaignDetails?.campaignData?._id,
            // }),
          },
        ],
        application_context: {
          return_url: "https://yourwebsite.com/success",
          cancel_url: "https://yourwebsite.com/cancel", 
        },
      });
      console.log("PayPal Order created:", orderData);

      // const paymentId = orderData.id;
      // console.log("paymentId>>>>>>", paymentId);

      return orderData;
    } catch (error) {
      console.error("Error creating PayPal order:", error);
      return null; 
    }
  };

  const onApprove = async (data, actions) => {
    try {
      const details = await actions.order.capture();
      console.log("PayPal details:", details);
      console.log(
        "Payment details:",
        details.purchase_units[0].payments.captures[0].id
      );

      const response = await axios.post(
        `https://devapi.grabit.org.in/api/utils/paymentWebhook/verifyPayPalPayment`,
        {
          paymentId: details.id,
          payerId: details.payer.payer_id,
        }
      );

      console.log("Server response:", response.data);

      setShowPopup(false);
    } catch (error) {
      console.error("Error during PayPal order capture:", error);
      console.log("There was an error completing your transaction.");
    }
  };

  // const onApprove = async(data, actions) => {
  //   return actions.order
  //     .capture()
  //     .then((details) => {
  //       console.log("details", details.status);
  //       // const res = await
  //       // console.log(
  //       //   "Transaction completed by " + details.payer.name.given_name
  //       // );
  //       setShowPopup(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error during order capture:", error);
  //       console.log("There was an error completing your transaction.");
  //     });
  // };

  const [isIntagramActivity, setisIntagramActivity] = useState("");
  const IntagramSubmit = async () => {
    const followInstagramActivity =
      WebCampaignDetails.campaignData.activityList.find(
        (activity) => activity.activityName === "Media Mania"
      );
    const activityIntagramId = followInstagramActivity
      ? followInstagramActivity.activityId
      : null;

    //  console.log("activityIntagramId", activityIntagramId);
    const response = await axios.post(`${URL}campaign/completeActivity`, {
      campaignId: WebCampaignDetails?.campaignData?._id,
      userId: WebCampaignDetails?.userData?.userId,
      activityId: activityIntagramId,
      activityData: inputValue,
    });
    if (response.status === 200) {
      setisIntagramActivity(true);
      localStorage.setItem("intragramActivity", "true");
    }
    setintagramPopupShow(false);
  };
  useEffect(() => {
    const paymentStatus = localStorage.getItem("intragramActivity");
    if (paymentStatus === "true") {
      setisIntagramActivity(true);
    }
  }, []);

  const googleSubmit = async () => {
    const GoogleReviewActivity =
      WebCampaignDetails.campaignData.activityList.find(
        (activity) => activity.activityName === "Google Glory"
      );
    const activityIntagramId = GoogleReviewActivity
      ? GoogleReviewActivity.activityId
      : null;

    const response = await axios.post(`${URL}campaign/completeActivity`, {
      campaignId: WebCampaignDetails?.campaignData?._id,
      userId: WebCampaignDetails?.userData?.userId,
      activityId: activityIntagramId,
      activityData: inputValue,
    });
    setgooglePopupShow(false);
    setInputValue("");
  };
  return (
    <div>
      <>
        {WebCampaignDetails?.campaignData?.isCompleted &&
        WebCampaignDetails?.campaignData?.isWinnerAnnouncement ? (
          ""
        ) : (
          <>
            <div className="pl-[20px] pr-[20px] backdrop-brightness-50">
              <div className="lg:pb-[50px] md:pb-[0px] ">
                <div>
                  <h1 className="font-bold lg:text-[50px] md:text-[50px] text-[18px] lg:leading-[63px] md:leading-[50px] leading-[22.68 px]  text-[#FFFFFF] lg:text-center">
                    Try Your Luck, Win Jackpot !!!
                  </h1>
                  <p className="font-medium lg:text-[20px] md:text-[20px] text-[12px] text-[#656565] lg:leading-[25.2px] leading-[15.12px] lg:text-center lg:pt-[9px] md:pt-[9px] pt-[5px]">
                    Follow Activities to win the prize
                  </p>
                </div>

                <div className="lg:pt-[50px] md:pt-[40px] pt-[20px] flex flex-wrap gap-[10px] justify-center pb-[30px]">
                  {WebCampaignDetails?.campaignData?.activityList.map(
                    (data, index) => {
                      return (
                        <>
                          <div
                            key={data.id}
                            className={`${
                              !isPaymentSuccessful
                                ? `bg-[#1C1C1C] relative p-[10px] flex justify-between items-center rounded-[20px] w-full lg:w-[49%] md:w-[100%] lg:mb-[50px] md:mb-[30px] mb-[10px] ${
                                    data.activityName === "Paywall"
                                      ? ""
                                      : "filter "
                                  }`
                                : "bg-[#1C1C1C] p-[10px] flex justify-between items-center rounded-[20px] w-full lg:w-[49%] md:w-[100%] lg:mb-[50px] md:mb-[30px] mb-[10px]"
                            }`}
                          >
                            <div>
                              <h2 className="xl:text-[25px] lg:text-[22px] md:text-[20px] text-[12px] text-[#FEFFFA] font-bold lg:leading-[31.5px] md:leading-[25px] leading-[17px] lottery-name">
                                {data.activityName}
                              </h2>
                              <p className="font-semibold text-[#656565] text-[12px] leading-[15.12px] pt-[10px] lottery-description">
                                {data.activityDescription}
                              </p>
                              {/* {data.activityName !== "Pay" &&
                                !isPaymentSuccessful && (
                                  <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-[#1C1C1C] bg-opacity-70 rounded-[20px] p-[10px]">
                                    <span className=" text-white font-semibold text-[12px]">
                                      🎉 First Pay ₹99/- & Unlock a World of
                                      Fun! 🎉
                                    </span>
                                  </div>
                                )} */}
                              {data.activityName === "Paywall" ? (
                                // <button
                                //   className="lg:py-[21px] md:py-[21px] py-[8.5px] lg:px-[50px] md:px-[50px] px-[15.5px] bg-[#62CD5D] text-[#1C1C1C] font-bold lg:text-[18px] md:text-[18px] text-[10px] leading-[22.68px] rounded-[20px] mt-[10px]"
                                //   onClick={handlePayment}
                                // >
                                //   To Complete
                                // </button>

                                <div className="relative inline-block">
                                  <button
                                    className={`lg:py-[21px] md:py-[21px] py-[8.5px] lg:px-[50px] md:px-[50px] px-[15.5px] 
                    ${
                      isPaymentSuccessful
                        ? "bg-[#656565] text-[#1C1C1C]"
                        : "bg-[#62CD5D] text-[#1C1C1C]"
                    } 
                    font-bold lg:text-[18px] md:text-[18px] text-[10px] leading-[22.68px] rounded-[20px] mt-[10px]`}
                                    onClick={togglePopup}
                                    disabled={isPaymentSuccessful}
                                  >
                                    {isPaymentSuccessful
                                      ? "Completed"
                                      : "To Complete"}
                                  </button>

                                  {/* {showPopup && (
                                    <div
                                      className="absolute right-[-247px] top-[45px] mt-2 bg-[#363636] shadow-lg p-4 w-[350px] rounded-[20px] payment-popup"
                                      ref={popupRef}
                                    > */}
                                  <Modal
                                    isOpen={showPopup}
                                    onRequestClose={togglePopup}
                                    contentLabel="Payment Selection"
                                    className="payment-popup"
                                    overlayClassName="modalOverlay"
                                  >
                                    <div className="flex flex-col space-y-2 bg-[#363636] p-[15px] shadow-lg rounded-[20px]">
                                      <div className="flex justify-between items-center">
                                        <h1 className="font-bold text-white">
                                          {WebCampaignDetails.userData
                                            .countryCode === "IN"
                                            ? "Select Payment Type"
                                            : "Paypal Payment"}
                                        </h1>
                                        <img
                                          src={IMG13}
                                          alt="img-close"
                                          className="w-[20px] h-[20px]"
                                          onClick={handleClosePopup}
                                        />
                                      </div>

                                      <div>
                                        {WebCampaignDetails.userData
                                          .countryCode === "IN" ? (
                                          <>
                                            <div
                                              className="flex items-center border-[0.2px] border-[#62CD5D] p-[7px] cursor-pointer mt-[10px] rounded-[16px]"
                                              onClick={() => {
                                                handleClick(0);
                                                handlePaymentRozarpay();
                                              }}
                                            >
                                              <span
                                                className={`rounded-full h-4 w-4 text-center border-[1px] mr-[20px] ml-[10px] ${
                                                  activeIndex === 0
                                                    ? "bg-white"
                                                    : "bg-transparent"
                                                }`}
                                              >
                                                {/* {activeIndex === 0 && (
                                            <span className="absolute h-3 w-3 rounded-full bg-white top-0 left-0"></span>
                                          )} */}
                                              </span>

                                              <SuccessPopup
                                                isOpen={isSuccessPopupOpen}
                                                onClose={() =>
                                                  setSuccessPopupOpen(false)
                                                }
                                              />

                                              <img
                                                src={IMG}
                                                alt="Rozarpay"
                                                className="w-[40px] h-[36px] mr-[20px]"
                                              />
                                              <p className="font-bold text-white">
                                                Razorpay
                                              </p>
                                            </div>
                                            <div
                                              className="flex items-center border-[0.2px] border-[#62CD5D] p-[7px] cursor-pointer mt-[10px] rounded-[16px]"
                                              onClick={() => {
                                                handleClick(1);
                                                handlePhonepePayment();
                                              }}
                                            >
                                              <span
                                                className={`rounded-full h-4 w-4 text-center border-[1px] mr-[20px] ml-[10px] ${
                                                  activeIndex === 1
                                                    ? "bg-white"
                                                    : "bg-transparent"
                                                }`}
                                              >
                                                {/* {activeIndex === 1 && (
                                            <span className="absolute h-3 w-3 rounded-full bg-white top-0 left-0"></span>
                                           )} */}
                                              </span>
                                              <img
                                                src={IMG2}
                                                alt="Phonepay"
                                                className="w-[40px] h-[36px] mr-[20px]"
                                              />
                                              <p className="font-bold text-white">
                                                Phonepe
                                              </p>
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}

                                        {/* <div
                                          className="flex items-center border-[0.2px] border-[#62CD5D] p-[7px] cursor-pointer mt-[10px] rounded-[16px]"
                                          onClick={() => {
                                            handleClick(2);
                                            handleCashfreePayment();
                                          }}
                                        >
                                          <span
                                            className={`rounded-full h-4 w-4 text-center border-[1px] mr-[20px] ml-[10px] ${
                                              activeIndex === 2
                                                ? "bg-white"
                                                : "bg-transparent"
                                            }`}
                                          >
                                          </span>
                                          <img
                                            src={IMG3}
                                            alt="Cashfree"
                                            className="w-[40px] h-[36px] mr-[20px]"
                                          />
                                          <p className="font-bold text-white">
                                            Cashfree
                                          </p>
                                        </div> */}

                                        <div
                                          className="flex items-center  p-[7px] cursor-pointer mt-[10px] rounded-[16px]"
                                          onClick={() => {
                                            handleClick(3);
                                            handlePaypalPayment();
                                          }}
                                        >
                                          {/* <span
                                            className={`rounded-full h-4 w-4 text-center border-[1px] mr-[20px] ml-[10px] ${
                                              activeIndex === 3
                                                ? "bg-white"
                                                : "bg-transparent"
                                            }`}
                                          ></span>
                                          <img
                                            src={IMG4}
                                            alt="Cashfree"
                                            className="w-[40px] h-[36px] mr-[20px]"
                                          /> */}
                                          {/* <p className="font-bold">Paypal</p> */}

                                          {/* {WebCampaignDetails.userData
                                            .countryCode === "GB" ? (
                                            <> */}

                                          <PayPalScriptProvider
                                            options={{
                                              "client-id":
                                                "ARMc33wDpiG3woSZ8uy1OfE1xzj1T58Seavx6SL0Sfh6PYfxm58AOXYTFnsWSB0jT1CiQicJwVITZhnK",
                                              currency: "USD",
                                              intent: "capture",
                                              // scret key
                                            }}
                                          >
                                            <div style={{ width: "100%" }}>
                                              <PayPalButtons
                                                style={{
                                                  layout: "horizontal",
                                                  // shape: "pill",
                                                  height: 40,
                                                  width: 300,
                                                  tagline: false,
                                                }}
                                                // createOrder={(data, action) =>
                                                //   createOrder(data, action)
                                                // }
                                                createOrder={createOrder}
                                                onApprove={(data, action) =>
                                                  onApprove(data, action)
                                                }
                                                // onError={onError}
                                              />
                                            </div>
                                          </PayPalScriptProvider>
                                          {/* </>
                                          ) : (
                                            ""
                                          )} */}
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>

                                  {/* </div>
                                  )} */}
                                </div>
                              ) : data.activityName === "Redeem Zone" ? (
                                <button
                                  className={`lg:py-[21px] md:py-[21px] py-[8.5px] lg:px-[50px] md:px-[50px] px-[15.5px] 
                    ${
                      !isPaymentSuccessful
                        ? "bg-[#656565] text-[#1C1C1C]"
                        : "bg-[#62CD5D] text-[#1C1C1C]"
                    } 
                    font-bold lg:text-[18px] md:text-[18px] text-[10px] leading-[22.68px] rounded-[20px] mt-[10px]`}
                                  onClick={handleReedeemPrize}
                                  disabled={!isPaymentSuccessful}
                                >
                                  To Complete
                                </button>
                              ) : data.activityName === "Media Mania" ? (
                                <>
                                  {showVerificationButton ? (
                                    <>
                                      <button
                                        className={`lg:py-[21px] md:py-[21px] py-[8.5px] lg:px-[50px] md:px-[50px] px-[15.5px] 
                    ${
                      !isPaymentSuccessful || isIntagramActivity
                        ? "bg-[#656565] text-[#1C1C1C]"
                        : "bg-[#62CD5D] text-[#1C1C1C]"
                    } 
                    font-bold lg:text-[18px] md:text-[18px] text-[10px] leading-[22.68px] rounded-[20px] mt-[10px]`}
                                        onClick={handleVerify}
                                        disabled={isIntagramActivity}
                                      >
                                        Verify
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className={`lg:py-[21px] md:py-[21px] py-[8.5px] lg:px-[50px] md:px-[50px] px-[15.5px] 
                    ${
                      !isPaymentSuccessful
                        ? "bg-[#656565] text-[#1C1C1C]"
                        : "bg-[#62CD5D] text-[#1C1C1C]"
                    } 
                    font-bold lg:text-[18px] md:text-[18px] text-[10px] leading-[22.68px] rounded-[20px] mt-[10px]`}
                                        onClick={handleInstagram}
                                        disabled={!isPaymentSuccessful}
                                      >
                                        To Complete
                                        {/* {buttonText} */}
                                      </button>
                                    </>
                                  )}
                                </>
                              ) : data.activityName === "Refer & Reward" ? (
                                <button
                                  className={`lg:py-[21px] md:py-[21px] py-[8.5px] lg:px-[50px] md:px-[50px] px-[15.5px] 
                    ${
                      !isPaymentSuccessful
                        ? "bg-[#656565] text-[#1C1C1C]"
                        : "bg-[#62CD5D] text-[#1C1C1C]"
                    } 
                    font-bold lg:text-[18px] md:text-[18px] text-[10px] leading-[22.68px] rounded-[20px] mt-[10px]`}
                                  onClick={handleInvitefriend}
                                  disabled={!isPaymentSuccessful}
                                >
                                  To Complete
                                </button>
                              ) : data.activityName === "Google Glory" ? (
                                <button
                                  className={`lg:py-[21px] md:py-[21px] py-[8.5px] lg:px-[50px] md:px-[50px] px-[15.5px] 
                    ${
                      !isPaymentSuccessful
                        ? "bg-[#656565] text-[#1C1C1C]"
                        : "bg-[#62CD5D] text-[#1C1C1C]"
                    } 
                    font-bold lg:text-[18px] md:text-[18px] text-[10px] leading-[22.68px] rounded-[20px] mt-[10px]`}
                                  onClick={handleGoogleReview}
                                  disabled={!isPaymentSuccessful}
                                >
                                  To Complete
                                </button>
                              ) : (
                                <button
                                  className={`lg:py-[21px] md:py-[21px] py-[8.5px] lg:px-[50px] md:px-[50px] px-[15.5px] 
                    ${
                      isPaymentSuccessful
                        ? "bg-[#656565] text-[#1C1C1C]"
                        : "bg-[#62CD5D] text-[#1C1C1C]"
                    } 
                    font-bold lg:text-[18px] md:text-[18px] text-[10px] leading-[22.68px] rounded-[20px] mt-[10px]`}
                                >
                                  To Complete
                                </button>
                              )}
                            </div>
                            <div>
                              <img
                                src={data.activityImages[0]}
                                alt="img"
                                className="lg:w-[174.55px] md:w-[174px] w-[111.63px] lg:h-[135px] md:h-[135px] h-[86.34px]"
                              />
                            </div>

                            {data.activityName !== "Paywall" &&
                              !isPaymentSuccessful && (
                                <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-[#1C1C1C] bg-opacity-70 rounded-[20px] p-[10px]">
                                  <span className=" text-white font-semibold text-[12px]">
                                    🎉 First Pay ₹99/- & Unlock a World of Fun!
                                    🎉
                                  </span>
                                </div>
                              )}
                          </div>
                        </>
                      );
                    }
                  )}
                </div>
              </div>
              <SuccessPopup
                isOpen={isSuccessPopupOpen}
                onClose={() => setSuccessPopupOpen(false)}
                paymentId={paymentInfo.id}
                amount={paymentInfo.amount}
              />
              <CommanPopup
                isOpen={intagramPopupShow}
                onClose={() => setintagramPopupShow(false)}
                title="Instagram Verification"
                imageSrc={intagram}
                label="Instagram Profile ID"
                footerText="Thanks for being there with us."
                onSubmit={IntagramSubmit}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
              <CommanPopup
                isOpen={googlePopupShow}
                onClose={() => setgooglePopupShow(false)}
                title="Google Verification"
                imageSrc={googleImg}
                label="Google ID"
                footerText="Thanks for being there with us."
                onSubmit={googleSubmit}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
              {/* <FailedPopup
                isOpen={isFaildPopupOpen}
                onClose={() => setFaildPopupOpen(false)}
              /> */}
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default Lottery;

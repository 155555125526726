import React, { useState, useEffect } from "react";
import "../GiveAwayBattle/GiveAway.css";
import IMG from "../../assets/images/App_Website.jpg";
import IMG1 from "../../assets/images/Image (2).png";
import IMG2 from "../../assets/images/Image (1).png";
import { useDispatch, useSelector } from "react-redux";
import IMG3 from "../../assets/images/Frame.png";
import moment from "moment";
import { setModalIsOpen, setShowPopup } from "../Slice/UserSlice";

const GiveAwayBattle = ({ ToggleMenu }) => {
  const GiveAwayBg = {
    backgroundImage: `url(${IMG3})`,
    backgroundSize: "cover",
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 720);
  const { WebCampaignDetails } = useSelector((state) => state.user);

  const [isCampaignClosed, setIsCampaignClosed] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  const dispatch = useDispatch();
  const startDate = new Date(
    WebCampaignDetails?.campaignData?.startDate
  ).getTime();
  const endDate = new Date(WebCampaignDetails?.campaignData?.endDate).getTime();

  const winnerAnnouncementDate = new Date(
    WebCampaignDetails?.campaignData?.winnerAnnouncementDate
  ).getTime();

  useEffect(() => {
    if (WebCampaignDetails?.campaignData?.isCompleted) {
      setIsCampaignClosed(true);
    } else {
      const interval = setInterval(() => {
        const now = new Date().getTime();

        if (now < startDate) {
          setTimeLeft({
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
          });
          return;
        } else if (now > endDate) {
          clearInterval(interval);
          setIsCampaignClosed(true);
          setTimeLeft({
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
          });
          return;
        }

        const distance = endDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeLeft({
          days: String(days).padStart(2, "0"),
          hours: String(hours).padStart(2, "0"),
          minutes: String(minutes).padStart(2, "0"),
          seconds: String(seconds).padStart(2, "0"),
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [startDate, endDate]);

  const calculateTimeLeft = () => {
    const now = moment();
    const winnerDate = moment(winnerAnnouncementDate);
    const difference = winnerDate.diff(now);

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const duration = moment.duration(difference);
    return {
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  };

  const [announcmentTimeLefts, setannouncmentTimeLefts] = useState(
    calculateTimeLeft()
  );
  const [winnerList, setwinnerList] = useState(false);

  useEffect(() => {
    if (WebCampaignDetails?.campaignData?.isWinnerAnnouncement) {
      setwinnerList(true);
    } else {
      setwinnerList(false);
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setannouncmentTimeLefts(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [winnerAnnouncementDate]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 720);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const [showPopup, setshowPopup] = useState(false)
  

  return (
    <div>
      <div style={GiveAwayBg} className="giveaway-battle-bg">
        <div className="flex items-center justify-between giveaway-battle-contain">
          <div className="giveaway-battle-img">
            <img
              src={isSmallScreen ? IMG : IMG1}
              alt="img-giveaway"
              className={
                isSmallScreen ? "w-full rounded-[20px] grabit-campaign-img" : ""
              }
            />
          </div>

          <div className="-translate-x-[150px] giveaway-title-box">
            <div className="giveaway-battle-title">
              <div>
                <h2 className="font-bold text-[60px] leading-[72px] text-[#FFFFFF] giveaway-battle-h">
                  Giveaway Battle
                </h2>
                <p className="text-[#FFFFFF] text-[20px] font-medium leading-[25.2px] max-w-[478px] pt-[12px] giveaway-battle-p">
                  Complete the challenge and unlock exclusive prizes from the
                  battle
                </p>
              </div>
            </div>
            <div className="pt-[50px] giveaway-bittle-time">
              <div className="bg-[#FFFFFF24] lg:w-[667px] md:w-[680px] w-full flex items-center px-[10px] py-[10px] justify-between rounded-[20px] giveaway-battle-time-show backdrop-blur-[10px]">
                {isCampaignClosed ? (
                  <>
                    <div className="text-[#FEFFFA]">
                      <p className="text-[20px] leading-[25px] flex give-winner-date">
                        Winner Announced :{" "}
                        {!winnerList ? (
                          <>
                            <div className="flex items-center justify-center ml-[10px]">
                              <div className="">
                                <div className="text-[20px] leading-[25px]">
                                  {announcmentTimeLefts.days} :{" "}
                                  {announcmentTimeLefts.hours} :{" "}
                                  {announcmentTimeLefts.minutes} :{" "}
                                  {announcmentTimeLefts.seconds}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {WebCampaignDetails?.campaignData
                              ?.winnerAnnouncementDate
                              ? moment(
                                  WebCampaignDetails.campaignData
                                    .winnerAnnouncementDate
                                ).format("DD/MM/YYYY")
                              : "Not Date"}
                          </>
                        )}
                      </p>
                      <div className="pt-[10px] flex justify-center">
                        <button className="font-semibold text-[12px] p-[8px] bg-[#656565] rounded-[10px]">
                          Campaign Closed
                        </button>
                      </div>
                      <div className="pt-[10px] flex justify-center">
                        <button
                          className={`px-[50px] py-[18px] text-[18px] leading-[22px] rounded-[20px] font-bold ${
                            winnerList
                              ? "bg-[#62CD5D] text-[#1C1C1C]"
                              : "bg-[#B0B0B0] text-[#7F7F7F]"
                          }`}
                          onClick={
                            winnerList
                              ? () => {
                                  ToggleMenu(true);
                                  dispatch(setModalIsOpen(true));
                                  // setshowPopup(true);
                                  // dispatch(setShowPopup(false));
                                }
                              : null
                          }
                          disabled={!winnerList}
                        >
                          {winnerList ? "Winner List" : "Winner List "}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="giveaway-battle-time-btn">
                      <button className="px-[50px] py-[21px] bg-[#62CD5D] font-bold text-[18px] text-[#1C1C1C] leading-[22.68px] rounded-[20px] give-away-btn-tocomplete">
                        Hurry Up
                      </button>
                    </div>
                    <span className="font-medium text-[20px] leading-[25.2px] text-[#FEFFFA] ml-[10px] dont-miss-text">
                      Tick-Tock! Grab Your Gift Before It’s Gone!
                    </span>
                    <div className="flex giveaway-time-days">
                      <div className="lg:w-[65px] md:w-[85px] w-[88px] h-[65px] flex justify-center items-center flex-col lg:ml-[10px] ml-[0px] pt-[12px] give-away-battle-hours">
                        <p className="text-[#62CD5D] text-[36px] font-bold leading-[15.12px] give-away-text-size">
                          {timeLeft.days}
                        </p>
                        <span className="pt-[16px] text-[10px] font-semibold leading-[15.12px] text-[#FEFFFA]">
                          Days
                        </span>
                      </div>
                      <span className="flex items-center text-[#62CD5D] giveaway-dot">
                        :
                      </span>
                      <div className="lg:w-[65px] md:w-[85px] w-[88px] h-[65px] flex justify-center items-center flex-col lg:ml-[10px] ml-[0px] pt-[12px] give-away-battle-hours">
                        <p className="text-[#FEFFFA] text-[36px] font-bold leading-[15.12px] give-away-text-size">
                          {timeLeft.hours}
                        </p>
                        <span className="pt-[16px] text-[10px] font-semibold leading-[15.12px] text-[#FEFFFA]">
                          Hours
                        </span>
                      </div>
                      <span className="flex items-center text-[#62CD5D] giveaway-dot">
                        :
                      </span>
                      <div className="lg:w-[65px] md:w-[85px] w-[88px] h-[65px] flex justify-center items-center flex-col lg:ml-[10px] ml-[0px] pt-[12px] give-away-battle-hours">
                        <p className="text-[#FEFFFA] text-[36px] font-bold leading-[15.12px] give-away-text-size">
                          {timeLeft.minutes}
                        </p>
                        <span className="pt-[16px] text-[10px] font-semibold leading-[15.12px] text-[#FEFFFA]">
                          Minutes
                        </span>
                      </div>
                      <span className="flex items-center text-[#62CD5D] giveaway-dot">
                        :
                      </span>
                      <div className="lg:w-[65px] md:w-[85px] w-[88px] h-[65px] flex justify-center items-center flex-col lg:ml-[10px] ml-[0px] pt-[12px] give-away-battle-hours">
                        <p className="text-[#62CD5D] text-[36px] font-bold leading-[15.12px] give-away-text-size">
                          {timeLeft.seconds}
                        </p>
                        <span className="pt-[16px] text-[10px] font-semibold leading-[15.12px] text-[#FEFFFA]">
                          Seconds
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="giveaway-img-megawin">
            <img
              src={IMG2}
              alt="img-giveaway"
              className="w-[529px] h-[358px] -translate-x-[80px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiveAwayBattle;

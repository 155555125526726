import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import img1 from "../../assets/images/Icon.svg";
import img2 from "../../assets/images/Iconpopup.svg";
import img3 from "../../assets/images/fi_9426995.png";
import img4 from "../../assets/images/pngfind.com-buttons-png-29055.png";
import Header from "../Header/Header";

Modal.setAppElement("#root");

const SuccessPopup = ({ isOpen, onClose, amount, paymentId }) => {
  //   const [isOpen, setIsOpen] = useState(false);

  //   useEffect(() => {
  //     setIsOpen(true);
  //     const timer = setTimeout(() => {
  //       setIsOpen(false);
  //     }, 300000);

  //     return () => clearTimeout(timer);
  //   }, []);

  return (
    <div className="">
      <Modal
        // onRequestClose={() => setIsOpen(false)}
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Success Modal"
        className="modalContents"
        overlayClassName="modalOverlay"
      >
        {/* <div className="flex justify-end mr-[8px] mt-[5px]">
          <img
            src={img4}
            alt="close-img"
            className="w-[30px] h-[30px]"
            //   onClick={onClose}
          />
        </div> */}

        <div className="px-[28px]">
          <div className="pt-[70px] flex justify-center items-center">
            <img src={img1} alt="popup-img" className="w-[172px] h-[163px]"/>
          </div>

          <h2 className="flex justify-center items-center font-bold text-[25px] leading-[31.5px] text-[#62CD5D] pt-[10px] Payment-successfully">
            Payment Successfully
          </h2>
          <div className="flex justify-center items-center">
            <p className="text-center pt-[10px] text-[#656565] text-[12px] leading-[15px] font-semibold w-[240px] ">
              Your payment of ₹99 was Successfully completed.
            </p>
          </div>
          <p className="text-center pt-[10px] text-[#656565] text-[12px] leading-[15px] font-semibold">
            Payment ID : 123456, 13 March 2024-12.12 PM
          </p>
          <div className="pt-[90px] ">
            <p className="text-[#FFFFFF] text-[12px] font-semibold leading-[15px] flex justify-center">
              Thanks for being there with us.
            </p>
            <button
              onClick={onClose}
              className="py-[25px] bg-[#62CD5D] text-[#1C1C1C] text-[16px] font-bold rounded-[20px] mt-[18px] mb-[30px] w-full"
            >
              Done
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SuccessPopup;
